import { showMessage } from '@/components/messages/GMessage';
import { toQueryParams } from '@/configs/Auth/utils/functions';
import { APIResponse } from '@/interfaces';
import lodash from '@/utils/lodash';
import dayts from './time';
import { RcFile } from 'antd/es/upload';

export const getOptionFromObj = (obj) => {
  return lodash.map(obj, (val: string, key: string) => ({
    label: val,
    value: key,
  }));
};

export function convertToOptions(
  collection: any[],
  value: string,
  label: string
): { value: number; label: string }[] {
  return lodash.reduce(
    collection,
    (total, item) => {
      if (!lodash.isNil(item)) {
        const option = {
          value: item?.[value],
          label: item?.[label],
        };
        return [...total, option];
      }
      return total;
    },
    []
  );
}

export function normalizeText(str: string) {
  if (typeof str !== 'string') return '';
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
}

export function searchValueOptions(input, option) {
  const label = normalizeText(option?.label?.toString());
  const value = option?.value?.toString();
  const searchString = normalizeText(input);

  return label?.indexOf(searchString) >= 0 || value === searchString;
}

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const uid = (len) => {
  const buf = [],
    chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    charlen = chars.length;
  for (let i = 0; i < len; ++i) buf.push(chars[getRandomInt(0, charlen - 1)]);
  return buf.join('');
};

export const getCurrentPath = () => {
  if (!window) return '/';
  const currentPath = window.location.href.replace(window.location.origin, '');
  return toQueryParams({ current: currentPath });
};

export const select = ({ data, error }: APIResponse) => {
  if (error) {
    showMessage.error(error);
    return { total: 0, records: [] };
  }
  return data;
};

export const filterInvalidField = (params): any => {
  return Object.keys(params).reduce(function (previous, key) {
    if (
      (Array.isArray(params[key]) && !params[key].length) ||
      params[key] === null ||
      params[key] === undefined ||
      params[key] === ''
    )
      return previous;
    return { [key]: params[key], ...previous };
  }, {});
};

export const sortNumber = (x: number, y: number) => x - y;

export const sortDate = (x: string, y: string, format = 'HH:mm:ss DD/MM/YYYY') => {
  if (!x || !y) return;
  return dayts(x, format).unix() - dayts(y, format).unix();
};

export const sortString = (x: string, y: string) => x?.toString()?.localeCompare(y?.toString());

const defaultSort = (a, b) => (a < b ? -1 : 1);

export const sorter = (obj1: object, obj2: object, keyCompare: string, sortFun: any = defaultSort) =>
  sortFun(obj1[keyCompare], obj2[keyCompare]);

export const capitalizeFirstLetter = (string) => string?.charAt?.(0)?.toUpperCase() + string?.slice(1);

export const selectData = ({ data, pagination, error }: APIResponse<any[]>) => {
  if (error) {
    showMessage.error(error);
  }

  return { records: data, pagination };
};

export const createQueryString = (obj: Record<string, any>): string => {
  return Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (!value) return null;
      const values = Array.isArray(value) ? value.join(',') : value;
      return `${key}=${encodeURIComponent(values)}`;
    })
    .filter((query) => query)
    .join('&');
};

export const convertMoney = (value: number, suffix?: string) => {
  if (!value || isNaN(value)) return `0 ${suffix}`;

  const inputValue = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${inputValue} ${suffix}`;
};
export const trueTypeOf = (value: any) => Object.prototype.toString.call(value).slice(8, -1).toLowerCase();

export const getIds = (array = [], id = 'id') => {
  return array?.map((i) => i[id]);
};

export const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const localString = (val: number) => {
  return val ? val.toLocaleString() : '';
};

export const convertToInternationalCurrencySystem = (labelValue: number, suffix: number = 2): string => {
  if (Math.abs(Number(labelValue)) >= 1.0e9)
    return `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(suffix)}B`;
  if (Math.abs(Number(labelValue)) >= 1.0e6)
    return `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(suffix)}M`;
  if (Math.abs(Number(labelValue)) >= 1.0e3)
    return `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(suffix)}K`;
  return String(Math.abs(Number(labelValue)));
};

export const subtractOne = (str) => {
  let result = '';
  let carry = 1; // Bắt đầu với 1 để trừ
  for (let i = str.length - 1; i >= 0; i--) {
    let digit = parseInt(str[i], 10) - carry;
    if (digit < 0) {
      digit = 9; // Nếu số âm, chuyển thành 9 và tiếp tục "mượn" 1
      carry = 1;
    } else {
      carry = 0; // Nếu không âm, dừng "mượn"
    }
    result = digit + result;
  }

  return result;
};

export const maskPhoneNumber = ({ phoneNumber }: { phoneNumber: string }) => {
  if (!phoneNumber || phoneNumber?.length < 5) return phoneNumber;

  const visibleStart = phoneNumber.slice(0, 3);
  const visibleEnd = phoneNumber.slice(-2);
  const maskedMiddle = '*'.repeat(phoneNumber.length - 5);

  return visibleStart + maskedMiddle + visibleEnd;
};

export const convertStringToArray = (value: string): string[] => {
  if (!value?.length) return;
  return value
    ?.split(/[, ]+/) // regex to convert comma or blank space to array
    ?.map((name) => name?.trim())
    ?.filter((name) => name);
};
